import {useCallback, useEffect} from 'react';
import {useDispatch} from 'react-redux';
import ScamHeader from '../../Elements/ScamHeader';

import {setSessionId} from '../../../redux/slices/appSlice';
import {setFlags} from '../../../redux/slices/narrativeSlice';

import './styles.scss';

const IntroScreen = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSessionId());
  }, [dispatch]);
  
  const handleStartGame = useCallback(() => {
    dispatch(setFlags('GAME_STARTED'))
  }, [dispatch]);

  return (
    <div className="intro-wrapper">
      <ScamHeader shadow={true} text="Are you scam savvy?" />
      <div className="intro-content">
        <p>Businesses of all sizes are at risk of fraud and scams. But small and medium-sized businesses (SMEs) are more at risk, because:</p>
        <ul>
          <li>they may have limited resources to counter fraud and scams</li>
          <li>there may not be regular training in place on how to identify and avoid fraud and scams</li>
          <li>there may be less of a clear split between work and home life, with work laptops used at home and personal mobile phones used at work</li>
        </ul>
        <p>Take part in this quick interactive experience to see some of the most common scams and practical advice on how small businesses can avoid them.</p>
        <button onClick={handleStartGame}>Get started <img alt="" src={require('../../../assets/img/icon-chevron-right.png')} /></button>
      </div>
    </div>
  );
};

export default IntroScreen;